// import React, { useEffect, useState } from "react";
// import { Grid, Typography, Box } from "@material-ui/core";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// // Material Dashboard 2 React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import { fetchDatatomato } from "./data/reportsPieCharttomato";
// import { fetchDatawheat } from "./data/reportpiechartwheat";
// import { fetchDatarice } from "./data/reportpiechartrice";


// // Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// // Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
// import PieChart from "examples/Charts/PieChart";
// import reportsPieChartTomato from "layouts/dashboard/data/reportsPieCharttomato";
// import reportsPieChartRice from "layouts/dashboard/data/reportpiechartrice";
// import reportsPieChartWheat from "layouts/dashboard/data/reportpiechartwheat";

// import { useUser } from "context/UserContext";
// import { useUserDispatch } from "context/UserContext";
// import { useNavigate } from "react-router-dom";


// const colorsTomato = ["grey", "#487800", "CRIMSON"];
// const labelsTomato = ["Total uploads", "Healthy", "Defected"];

// const colorsWheat = ["grey", "#5072A7", "#F6ADC6", "#E3256B"];
// const labelsWheat = ["Total uploads", "High Protein", "Low Protein", "Unknown"];

// const colorsRice = ["grey", "#915c83", "#F6ADC6", "#89cff0", " #BF4F51", "#9F8170", "orange"];
// const labelsRice = ["Total uploads", "arborio", "basmati", "jasmine", "ipsala", "karacadag", "unknown"];

// const tomatolagend =  <Legend colors={colorsTomato} labels={labelsTomato} />
// const wheatlagend = <Legend colors={colorsWheat} labels={labelsWheat} />
// const ricelagend = <Legend colors={colorsRice} labels={labelsRice} />



// function Legend({ colors, labels }) {
//   return (
//     <Box display="flex" flexWrap="wrap" mt={2}>
//       {labels.map((label, index) => (
//         <Box key={label} display="flex" alignItems="center" mr={2} mb={1}>
//           <Box
//             sx={{
//               width: 8,
//               height: 10,
//               backgroundColor: colors[index],
//               marginRight: 1,
//             }}
//           />
//           <Typography>{label}</Typography>
//         </Box>
//       ))}
//     </Box>
//   );
// }




// function Dashboard() {
//   const { sales, tasks } = reportsLineChartData;
//   const { user } = useUser();
//   const userDispatch = useUserDispatch();
//   const navigate = useNavigate();
//   const extractUsername = (email) => {
//     const parts = email.split("@");
 
//     if (parts.length === 2) {
//       return parts[0];
//     } else {
//       return "Invalid Email";
//     }
//   };

//   const username = user ? extractUsername(user.email) : null;

//   const [chartData1, setChartData1] = useState(null);
//   useEffect(() => {
//     if (username) {
//       fetchDatatomato("tomato", username)
//         .then(data => {
//           if (data.datasets.data[0] !== 0) {
//           setChartData1(data);
//         }
//     })
//         .catch(error => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [username]);
 
//   const [chartData2, setChartData2] = useState(null);

//   useEffect(() => {
//     if (username) {
//       fetchDatawheat("wheat", username)
//         .then(data => {
//           if (data.datasets.data[0] !== 0) {
//             setChartData2(data);
//           }
//         })
//         .catch(error => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [username]);

//   const [chartData3, setChartData3] = useState(null);

//   useEffect(() => {
//     if (username) {
//       fetchDatarice("rice", username)
//         .then(data => {
//           if (data.datasets.data[0] !== 0) {
//           setChartData3(data);
//         }
//     })
//         .catch(error => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [username]);
  
//   if (!username) {
//     // If not available, navigate to the login page
//     navigate('/Login');
//     return null; // Return null to prevent rendering the rest of the component
//   }
//   return (
//     <DashboardLayout>
//     <DashboardNavbar />
//     <MDBox py={3}>
//       <Typography variant="h4" className="text-center mb-4">
//         {/* UserName: {username} */}
//       </Typography>

//       <MDBox mt={4.5}>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <MDBox mb={3}>
//               <ReportsBarChart
//                 color="info"
//                 title="Comparison of Rice, Wheat, and Tomato Prediction"
//                 description="Illustrates the Prediction levels of rice, wheat, and tomatoes"
//                 chart={reportsBarChartData}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 icon="leaderboard"
//                 title="Today's Users"
//                 count="2,300"
//                 percentage={{
//                   color: "success",
//                   amount: "+3%",
//                   label: "than last month",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="success"
//                 icon="store"
//                 title="Healthy"
//                 count="+25"
//                 percentage={{
//                   color: "success",
//                   amount: "+1%",
//                   label: "Number Of healthy",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={3}>
//             <MDBox mb={1.5}>
//               <ComplexStatisticsCard
//                 color="primary"
//                 icon="person_add"
//                 title="Defected"
//                 count="+91"
//                 percentage={{
//                   color: "success",
//                   amount: "+1%",
//                   label: "Number Of Defected Tomatos",
//                 }}
//               />
//             </MDBox>
//           </Grid>
//         </Grid> */}
       
//         <MDBox mt={4.5}>
//         <Grid item xs={12} md={6} lg={12}>
//               <MDBox mb={3}>
//                 <ReportsBarChart
//                   color="info"
//                   title=" Comparison of Rice, Wheat, and Tomato Prediction"
//                   description="Illustrates the Prediction levels of rice, wheat, and tomatoes"
//                   chart={reportsBarChartData}
//                 />
//               </MDBox>
//             </Grid>
//           <Grid container spacing={3}>
//             {/* <Grid item xs={12} md={6} lg={4}>
//               <MDBox mb={3}>
//                 <ReportsBarChart
//                   color="info"
//                   title="website views"
//                   description="Last Campaign Performance"
//                   date="campaign sent 2 days ago"
//                   chart={chartData3}
//                   icon={{ color: "primary" }}
//                   height="16.5625rem"
//                 />
//               ) : (
//                 <MDBox>{chartData3 === null ? "" : ""}</MDBox>
//               )}
//             </MDBox>
//           </Grid>

//           <Grid item xs={12} md={6} lg={4}>
//             <MDBox mb={3} p={1}>
//               {chartData2 ? (
//                 <PieChart
//                   title="Wheat"
//                   description={wheatlagend}
//                   date="campaign sent 2 days ago"
//                   chart={chartData2}
//                   icon={{ color: "primary" }}
//                   height="16.5625rem"
//                 />
//               ) : (
//                 <MDBox>{chartData2 === null ? "" : ""}</MDBox>
//               )}
//             </MDBox>
//           </Grid>

//           <Grid item xs={12} md={6} lg={4}>
//             <MDBox mb={3} p={1}>
//               {chartData1 ? (
//                 <PieChart
//                   title="Tomato"
//                   description={tomatolagend}
//                   date="campaign sent 2 days ago"
//                   chart={chartData1}
//                   icon={{ color: "primary" }}
//                   height="16.5625rem"
//                 />
//               ) : (
//                 <MDBox>{chartData1 === null ? "" : ""}</MDBox>
//               )}
//             </MDBox>
//           </Grid>

//           {/* Uncomment and adjust these as needed */}
//           {/* <Grid item xs={12} md={6} lg={4}>
//             <MDBox mb={3}>
//               <ReportsLineChart
//                 color="primary"
//                 title="daily sales"
//                 description={(<><strong>+15%</strong> increase in today sales.</>)}
//                 date="updated 4 min ago"
//                 chart={sales}
//               />
//             </MDBox>
//           </Grid>
//           <Grid item xs={12} md={6} lg={4}>
//             <MDBox mb={3}>
//               <ReportsLineChart
//                 color="dark"
//                 title="completed tasks"
//                 description="Last Campaign Performance"
//                 date="just updated"
//                 chart={tasks}
//               />
//             </MDBox>
//           </Grid> */}
//         </Grid>
//       </MDBox>

//       <MDBox>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={6} lg={8}>
//             <Projects />
//           </Grid>
//           <Grid item xs={12} md={6} lg={4}>
//             <OrdersOverview />
//           </Grid>
//         </Grid>
//       </MDBox>
//     </MDBox>
//     <Footer />
//   </DashboardLayout>
//   );
// }

// export default Dashboard;
import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { fetchDatatomato } from "./data/reportsPieCharttomato";
import { fetchDatawheat } from "./data/reportpiechartwheat";
import { fetchDatarice } from "./data/reportpiechartrice";


// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import PieChart from "examples/Charts/PieChart";
import reportsPieChartTomato from "layouts/dashboard/data/reportsPieCharttomato";
import reportsPieChartRice from "layouts/dashboard/data/reportpiechartrice";
import reportsPieChartWheat from "layouts/dashboard/data/reportpiechartwheat";

import { useUser } from "context/UserContext";
import { useUserDispatch } from "context/UserContext";
import { useNavigate } from "react-router-dom";


const colorsTomato = ["grey", "#487800", "CRIMSON"];
const labelsTomato = ["Total uploads", "Healthy", "Defected"];

const colorsWheat = ["grey", "#5072A7", "#F6ADC6", "#E3256B"];
const labelsWheat = ["Total uploads", "High Protein", "Low Protein", "Unknown"];

const colorsRice = ["grey", "#915c83", "#F6ADC6", "#89cff0", " #BF4F51", "#9F8170", "orange"];
const labelsRice = ["Total uploads", "arborio", "basmati", "jasmine", "ipsala", "karacadag", "unknown"];

const tomatolagend =  <Legend colors={colorsTomato} labels={labelsTomato} />
const wheatlagend = <Legend colors={colorsWheat} labels={labelsWheat} />
const ricelagend = <Legend colors={colorsRice} labels={labelsRice} />



function Legend({ colors, labels }) {
  return (
    <Box display="flex" flexWrap="wrap" mt={2}>
      {labels.map((label, index) => (
        <Box key={label} display="flex" alignItems="center" mr={2} mb={1}>
          <Box
            sx={{
              width: 8,
              height: 10,
              backgroundColor: colors[index],
              marginRight: 1,
            }}
          />
          <Typography>{label}</Typography>
        </Box>
      ))}
    </Box>
  );
}




function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const { user } = useUser();
  const userDispatch = useUserDispatch();
  const navigate = useNavigate();
  const extractUsername = (email) => {
    const parts = email.split("@");
 
    if (parts.length === 2) {
      return parts[0];
    } else {
      return "Invalid Email";
    }
  };

  const username = user ? extractUsername(user.email) : null;

  const [chartData1, setChartData1] = useState(null);
  useEffect(() => {
    if (username) {
      fetchDatatomato("tomato", username)
        .then(data => {
          if (data.datasets.data[0] !== 0) {
          setChartData1(data);
        }
    })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    }
  }, [username]);
 
  const [chartData2, setChartData2] = useState(null);

  useEffect(() => {
    if (username) {
      fetchDatawheat("wheat", username)
        .then(data => {
          if (data.datasets.data[0] !== 0) {
            setChartData2(data);
          }
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    }
  }, [username]);

  const [chartData3, setChartData3] = useState(null);

  useEffect(() => {
    if (username) {
      fetchDatarice("rice", username)
        .then(data => {
          if (data.datasets.data[0] !== 0) {
          setChartData3(data);
        }
    })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    }
  }, [username]);
  
  // if (!username) {
  //   // If not available, navigate to the login page
  //   navigate('/Login');
  //   return null; // Return null to prevent rendering the rest of the component
  // }
 
  
  return (
    <DashboardLayout>
      <DashboardNavbar />      <Typography variant="h4" className="text-center mb-4">
        {/* UserName: {username} */}
        </Typography>
      <MDBox py={3}>

        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Bookings"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Today's Users"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Healthy"
                count="+25"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "Number Of healthy",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Defected"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "Number Of Defected Tomatos",
                }}
              />
            </MDBox>
          </Grid>
        </Grid> */}
       
        <MDBox mt={4.5}>
        <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title=" Comparison of Rice, Wheat, and Tomato Prediction"
                  description="Illustrates the Prediction levels of rice, wheat, and tomatoes"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3} p={1} >
            {chartData3 ? (
               <>
          <PieChart
          title="Rice"
          description={ricelagend}
         date="campaign sent 2 days ago"
         chart={chartData3}
         icon={{
          color: "primary",
          }}
          height="16.5625rem" 
        />
        </>
       ) : (
       <div>
        {chartData3 === null ? (
          "" 
        ) : (
          "" 
        )}
       </div>
         )}
      </MDBox>
      </Grid>
                <Grid item xs={12} md={8} lg={4} >
             <MDBox mb={3} p={0.649} >
          {chartData2 ? (
            <>
          <PieChart
          title="Wheat"
          description={wheatlagend}
          date="campaign sent 2 days ago"
          chart={chartData2}
          icon={{
          color: "primary",
          }}
          height="16.5625rem" 
         />
         </>
         ) : (
         <div>
         {chartData2 === null ? (
          "" 
         ) : (
          "" 
         )}
       </div>
     )}
       </MDBox>
       </Grid>

            <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}   style={{ marginTop: "7px" }}  >
              {chartData1 ? (
                <>
         <PieChart
         title="Tomato"
         description={tomatolagend}
         date="campaign sent 2 days ago"
         chart={chartData1}
         icon={{
          color: "primary",
          }}
          height="16.5625rem" 
       />
        </>
           ) : (
        <div>
        {chartData1 === null ? (
          "" 
        ) : (
          "" 
        )}
       </div>
       )}
      </MDBox>
     </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="primary"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {/* <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                /> */}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;