// import React, { useState } from "react";
// import {
//   Typography,
//   Card,
//   CardMedia,
//   CardContent,
//   Box,
//   Container,
//   Modal,
//   Button,
// } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import { useNavigate } from "react-router-dom";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import b1 from "../assets/images/bg1.jpg";
// import b2 from "../assets/images/declan-sun-ovR8zX_rk6I-unsplash.jpg";
// import b3 from "../assets/images/image-classification.jpg";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// const useStyles = makeStyles((theme) => ({
//   root: { display: "flex" },
//   sidebar: { width: 250, flexShrink: 0 },
//   drawerPaper: { width: 250 },
//   mainContent: { flexGrow: 1, padding: theme.spacing(3) },
//   section: { margin: theme.spacing(2, 0), display: "flex", cursor: "pointer" },
//   sectionImage: { width: 1100, height: 200, objectFit: "cover" },
//   modalContent: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 400,
//     backgroundColor: "white",
//     boxShadow: 24,
//     padding: theme.spacing(4),
//   },
// }));

// function SeedManagementPlatform() {
//   const classes = useStyles();
//   const [modalOpen, setModalOpen] = useState(false);
//   const navigate = useNavigate();

//   const handleOpenModal = () => setModalOpen(true);
//   const handleCloseModal = () => setModalOpen(false);
//   const navigateTo = (page) => navigate(page);

//   return (
//     <DashboardLayout>
//       <DashboardNavbar/>
//       <div className={classes.root}>
//         <main className={classes.mainContent}>
//           <Container>
//             <Box sx={{ textAlign: "center", my: 4 }}>
//               <Typography variant="h4">SeedzHub</Typography>
//             </Box>

//             {/* Seed Acquisition Section */}
//             <Card
//               className={classes.section}
//               component="a"
//               onClick={handleOpenModal}
//             >
//               <CardMedia
//                 className={classes.sectionImage}
//                 image={b1} // Ensure this path is correct
//                 title="Seed Acquisition"
//               />
//               <CardContent>
//                 <Typography variant="h5">Seed Acquisition</Typography>
//                 <ul>
//                   <li>
//                     Collection of seed materials from various sources for
//                     research and breeding programs.
//                   </li>
//                   <li>
//                     Ensures accurate tracking and documentation of seed origin,
//                     variety, and quantity.
//                   </li>
//                   <li>
//                     Involves careful management of seed inventory for future
//                     research or distribution.
//                   </li>
//                   <li>
//                     Data is stored systematically to streamline the seed
//                     management process.
//                   </li>
//                 </ul>
//               </CardContent>
//             </Card>

//             {/* Seed Characterization Section */}
//             <Card
//               className={classes.section}
//               component="a"
//               href="/characterization"
//             >
//               <CardMedia
//                 className={classes.sectionImage}
//                 image={b2} // Confirm the path
//                 title="Seed Characterization"
//               />
//               <CardContent>
//                 <Typography variant="h5">Seed Characterization</Typography>
//                 <ul>
//                   <li>
//                     Focuses on identifying key physical and genetic traits of
//                     seeds.
//                   </li>
//                   <li>
//                     Helps in determining seed viability and suitability for
//                     specific environments.
//                   </li>
//                   <li>
//                     Characterization includes data on size, color, texture, and
//                     genetic diversity.
//                   </li>
//                   <li>
//                     Plays a crucial role in selecting the best seeds for crop
//                     improvement programs.
//                   </li>
//                 </ul>
//               </CardContent>
//             </Card>

//             {/* Seed Classification Section */}
//             <Card
//               className={classes.section}
//               component="a"
//               href="/classification"
//             >
//               <CardMedia
//                 className={classes.sectionImage}
//                 image={b3}
//                 title="Seed Classification"
//                 sx={{ height: 200 }} // Set height to ensure image visibility
//               />
//               <CardContent>
//                 <Typography variant="h5">Seed Classification</Typography>
//                 <ul>
//                   <li>
//                     Classify the seed variety using advanced ML algorithms.
//                   </li>
//                 </ul>
//               </CardContent>
//             </Card>
//           </Container>

//           {/* Modal for Seed Type Selection */}
//           <Modal open={modalOpen} onClose={handleCloseModal}>
//             <Box className={classes.modalContent}>
//               <Typography variant="h6">Select Seed Type</Typography>
//               <Box mt={2}>
//                 <Button
//                   variant="contained"
//                   color="info"
//                   fullWidth
//                   onClick={() => navigateTo("/aquisition")}
//                 >
//                   Rice/Tomato
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="info"
//                   fullWidth
//                   sx={{ mt: 2 }}
//                   onClick={() => navigateTo("/aquisition-chilli")}
//                 >
//                   Chilli
//                 </Button>
//               </Box>
//             </Box>
//           </Modal>
//         </main>
//       </div>
//     </DashboardLayout>
//   );
// }

// export default SeedManagementPlatform;

import React, { useState } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  Container,
  Modal,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import b1 from "../assets/images/bg1.jpg";
import b2 from "../assets/images/declan-sun-ovR8zX_rk6I-unsplash.jpg";
import b3 from "../assets/images/image-classification.jpg";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
  mainContent: { flexGrow: 1, padding: theme.spacing(3) },
  section: { 
    display: "flex",
    flexDirection: "column", // Stacks image and content vertically on small screens
    cursor: "pointer",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: { 
      flexDirection: "row", // Aligns image and content side by side on larger screens
      alignItems: "center", // Vertically aligns content next to the image
    },
  },
  sectionImage: {
    width: "90%", 
    height: 150,
    objectFit: "cover",
    [theme.breakpoints.up("sm")]: {
      width: "90%", // Fixed width for larger screens
      height: 200, // Set a taller height for larger screens

    },
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    boxShadow: 24,
    padding: theme.spacing(4),
  },
}));

function SeedManagementPlatform() {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const navigateTo = (page) => navigate(page);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={classes.root}>
        <main className={classes.mainContent}>
          <Container>
            <Box sx={{ textAlign: "center", my: 4 }}>
              <Typography variant="h4">SeedzHub</Typography>
            </Box>

            {/* Seed Acquisition Section */}
            <Card
              className={classes.section}
              component="a"
              onClick={handleOpenModal}
            >
              <CardMedia
                className={classes.sectionImage}
                image={b1} // Ensure this path is correct
                title="Seed Acquisition"
              />
              <CardContent>
                <Typography variant="h5">Seed Acquisition</Typography>
                <ul>
                  <li>Collection of seed materials from various sources for research and breeding programs.</li>
                  <li>Ensures accurate tracking and documentation of seed origin, variety, and quantity.</li>
                  <li>Involves careful management of seed inventory for future research or distribution.</li>
                  <li>Data is stored systematically to streamline the seed management process.</li>
                </ul>
              </CardContent>
            </Card>

            {/* Seed Characterization Section */}
            <Card
              className={classes.section}
              component="a"
              href="/characterization"
            >
              <CardMedia
                className={classes.sectionImage}
                image={b2} // Confirm the path
                title="Seed Characterization"
              />
              <CardContent>
                <Typography variant="h5">Seed Characterization</Typography>
                <ul>
                  <li>Focuses on identifying key physical and genetic traits of seeds.</li>
                  <li>Helps in determining seed viability and suitability for specific environments.</li>
                  <li>Characterization includes data on size, color, texture, and genetic diversity.</li>
                  <li>Plays a crucial role in selecting the best seeds for crop improvement programs.</li>
                </ul>
              </CardContent>
            </Card>

            {/* Seed Classification Section */}
            <Card
              className={classes.section}
              component="a"
              href="/classification"
            >
              <CardMedia
                className={classes.sectionImage}
                image={b3}
                title="Seed Classification"
              />
              <CardContent>
                <Typography variant="h5">Seed Classification</Typography>
                <ul>
                  <li>Classify the seed variety using advanced ML algorithms.</li>
                </ul>
              </CardContent>
            </Card>
          </Container>

          {/* Modal for Seed Type Selection */}
          <Modal open={modalOpen} onClose={handleCloseModal}>
            <Box className={classes.modalContent}>
              <Typography variant="h6">Select Seed Type</Typography>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  onClick={() => navigateTo("/aquisition")}
                >
                  Rice/Tomato
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={() => navigateTo("/aquisition-chilli")}
                >
                  Chilli
                </Button>
              </Box>
            </Box>
          </Modal>
        </main>
      </div>
    </DashboardLayout>
  );
}

export default SeedManagementPlatform;
