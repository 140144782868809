import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
  Container,
} from '@mui/material';
import { Alert } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDButton from 'components/MDButton';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

const SeedAcquisitionFormChilli = () => {
  const [formData, setFormData] = useState({
    seedName: 'Chilli',
    acquisitionDate: '',
    speciesName: 'Capsicum annuum',
    originCountry: 'India',
    originState: 'Karnataka',
    seedQuantity: 1,
    climateGrown: 'Tropical',
    fertilizersUsed: 'Organic',
    soilType: 'Sandy',
    sowing: 'Yes',
    gridNumber: '',
    sowedSoil: 'Cocopeat',
    seedNumber: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const [seedNumberGenerated, setSeedNumberGenerated] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setFormData((prev) => ({ ...prev, acquisitionDate: today }));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const generateSeedNumber = () => {
    const randomString = Math.floor(Math.random() * 1000000).toString();
    const seedNumber = (
      formData.seedName.substring(0, 2) +
      formData.speciesName.substring(0, 2) +
      formData.originCountry.substring(0, 2) +
      formData.originState.substring(0, 2)
    ).toUpperCase() + randomString;

    setFormData((prev) => ({ ...prev, seedNumber }));
    setSeedNumberGenerated(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiUrl = 'https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/dataCollection';
    const apiKey = 'vRtW4LOFB61VjXvcvRtKu6QOItTs7b7w4d2QoI2n';
    let success = false;

    try {
      if (imageFile) {
        const imageApiUrl = `https://mexqi5uelf.execute-api.ap-south-1.amazonaws.com/dev/digi-s3-bucket/SeedAcquisition_images/${encodeURIComponent(formData.seedName)}/${encodeURIComponent(imageFile.name)}`;
        
        const imageUploadResponse = await fetch(imageApiUrl, {
          method: 'PUT',
          headers: { 'Content-Type': imageFile.type, 'x-api-key': apiKey },
          body: imageFile,
        });
        if (!imageUploadResponse.ok) throw new Error('Image upload failed');
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      success = response.ok;

    } catch (error) {
      console.error('Error:', error);
    } finally {
      alert(success ? 'Form data and image uploaded successfully.' : 'Failed to submit form data.');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar/>
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>Seed Acquisition Form</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Seed Name"
          name="seedName"
          value={formData.seedName}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Acquisition Date"
          name="acquisitionDate"
          value={formData.acquisitionDate}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          type="date"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Species Name"
          name="speciesName"
          value={formData.speciesName}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Origin Country"
          name="originCountry"
          value={formData.originCountry}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Origin State"
          name="originState"
          value={formData.originState}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Seed Quantity (gms)"
          name="seedQuantity"
          value={formData.seedQuantity}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Climate Grown"
          name="climateGrown"
          value={formData.climateGrown}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Fertilizers Used"
          name="fertilizersUsed"
          value={formData.fertilizersUsed}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <TextField
          label="Soil Type"
          name="soilType"
          value={formData.soilType}
          InputProps={{ readOnly: true }}
          fullWidth
          margin="normal"
          sx={{width: '60%', height: '40px'}}

        />

        <FormControl component="fieldset" margin="normal" fullWidth>
          <FormLabel component="legend">Are you sowing?</FormLabel>
          <RadioGroup
            row
            name="sowing"
            value={formData.sowing}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" disabled />
          </RadioGroup>
        </FormControl>

        {formData.sowing === 'Yes' && (
          <>
            <TextField
              label="Grid Number"
              name="gridNumber"
              value={formData.gridNumber}
              onChange={handleChange}
              required
              fullWidth
              margin="normal"
              sx={{width: '60%', height: '40px'}}

            />
            <TextField
              label="Type of Soil seed sowed"
              name="sowedSoil"
              value={formData.sowedSoil}
              InputProps={{ readOnly: true }}
              fullWidth
              margin="normal"
              sx={{width: '60%', height: '40px'}}

            />
          </>
        )}

        <InputLabel htmlFor="seedImage">Upload Image</InputLabel>
        <input
          id="seedImage"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'block', marginBottom: '16px' }}
        />

        {seedNumberGenerated && (
          <TextField
            label="Seed Number"
            name="seedNumber"
            value={formData.seedNumber}
            InputProps={{ readOnly: true }}
            fullWidth
            margin="normal"
          />
        )}

        <Grid container spacing={2}>
          <Grid item>
            <MDButton
              variant="contained"
              onClick={generateSeedNumber}
              disabled={seedNumberGenerated}
              color="info"
            >
              Generate Seed Number
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton variant="contained" type="submit" color="success" disabled={!seedNumberGenerated}>
              Submit
            </MDButton>
          </Grid>
        </Grid>
      </form>
    </Container>
    </DashboardLayout>
  );
};

export default SeedAcquisitionFormChilli;