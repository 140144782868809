// /**
// =========================================================
// * Material Dashboard 2 React - v2.2.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// import { useState, useEffect, useMemo } from "react";

// // react-router components
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// // @mui material components
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// // Material Dashboard 2 React example components
// import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// // Material Dashboard 2 React themes
// import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// // Material Dashboard 2 React Dark Mode themes
// import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";

// // RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// // Material Dashboard 2 React routes
// import routes from "routes";

// // Material Dashboard 2 React contexts
// import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// // Images
// import brandWhite from "assets/images/logo-ct.png";
// import brandDark from "assets/images/logo-ct-dark.png";

// export default function App() {
//   const [controller, dispatch] = useMaterialUIController();
//   const {
//     miniSidenav,
//     direction,
//     layout,
//     openConfigurator,
//     sidenavColor,
//     transparentSidenav,
//     whiteSidenav,
//     darkMode,
//   } = controller;
//   const [onMouseEnter, setOnMouseEnter] = useState(false);
//   const [rtlCache, setRtlCache] = useState(null);
//   const { pathname } = useLocation();

//   // Cache for the rtl
//   useMemo(() => {
//     const cacheRtl = createCache({
//       key: "rtl",
//       stylisPlugins: [rtlPlugin],
//     });

//     setRtlCache(cacheRtl);
//   }, []);

//   // Open sidenav when mouse enter on mini sidenav
//   const handleOnMouseEnter = () => {
//     if (miniSidenav && !onMouseEnter) {
//       setMiniSidenav(dispatch, false);
//       setOnMouseEnter(true);
//     }
//   };

//   // Close sidenav when mouse leave mini sidenav
//   const handleOnMouseLeave = () => {
//     if (onMouseEnter) {
//       setMiniSidenav(dispatch, true);
//       setOnMouseEnter(false);
//     }
//   };

//   // Change the openConfigurator state
//   const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

//   // Setting the dir attribute for the body element
//   useEffect(() => {
//     document.body.setAttribute("dir", direction);
//   }, [direction]);

//   // Setting page scroll to 0 when changing the route
//   useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//   }, [pathname]);

//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }

//       if (route.route) {
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }

//       return null;
//     });

//   const configsButton = (
//     <MDBox
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       width="3.25rem"
//       height="3.25rem"
//       bgColor="white"
//       shadow="sm"
//       borderRadius="50%"
//       position="fixed"
//       right="2rem"
//       bottom="2rem"
//       zIndex={99}
//       color="dark"
//       sx={{ cursor: "pointer" }}
//       onClick={handleConfiguratorOpen}
//     >
//       <Icon fontSize="small" color="inherit">
//         settings
//       </Icon>
//     </MDBox>
//   );

//   return direction === "rtl" ? (
//     <CacheProvider value={rtlCache}>
//       <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
//         <CssBaseline />
//         {layout === "dashboard" && (
//           <>
//             <Sidenav
//               color={sidenavColor}
//               brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
//               brandName="Material Dashboard 2"
//               routes={routes}
//               onMouseEnter={handleOnMouseEnter}
//               onMouseLeave={handleOnMouseLeave}
//             />
//             <Configurator />
//             {configsButton}
//           </>
//         )}
//         {layout === "vr" && <Configurator />}
//         <Routes>
//           {getRoutes(routes)}
//           <Route path="*" element={<Navigate to="/dashboard" />} />
//         </Routes>
//       </ThemeProvider>
//     </CacheProvider>
//   ) : (
//     <ThemeProvider theme={darkMode ? themeDark : theme}>
//       <CssBaseline />
//       {layout === "dashboard" && (
//         <>
//           <Sidenav
//             color={sidenavColor}
//             brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
//             brandName="DIGI Tomato"
//             routes={routes}
//             onMouseEnter={handleOnMouseEnter}
//             onMouseLeave={handleOnMouseLeave}
//           />
//           <Configurator />
//           {configsButton}
//         </>
//       )}
//       {layout === "vr" && <Configurator />}
//       <Routes>
//         {getRoutes(routes)}
//         <Route path="*" element={<Navigate to="/Home" />} />
//       </Routes>
//     </ThemeProvider>
//   );
// }
// import { useState, useEffect, useMemo } from "react";
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";
// import MDBox from "components/MDBox";
// import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";
// import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";
// import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";
// import routes from "routes";
// import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
// import brandWhite from "assets/images/logo-ct.png";
// import brandDark from "assets/images/logo-ct-dark.png";
// import Basic from "layouts/authentication/sign-in";
// import { useUser } from "context/UserContext";
// import { UserProvider } from "context/UserContext";
// // import Footer from "./examples/Footer";
// import Footer from "layouts/authentication/components/Footer";




// export default function App() {
//   const [controller, dispatch] = useMaterialUIController();
//   const {
//     miniSidenav,
//     direction,
//     layout,
//     openConfigurator,
//     sidenavColor,
//     transparentSidenav,
//     whiteSidenav,
//     darkMode,
//   } = controller;
//   const [onMouseEnter, setOnMouseEnter] = useState(false);
//   const [rtlCache, setRtlCache] = useState(null);
//   const { pathname } = useLocation();
//   const user = useUser();
  

// // const extractUsername = (email) => {
// //   const parts = email.split("@");

// //   if (parts.length === 2) {
// //     return parts[0];
// //   } else {
// //     return "Invalid Email";
// //   }
// // };

// // const username = user ? extractUsername(user.email) : null;

// // console.log("username:", username);


//   useMemo(() => {
//     const cacheRtl = createCache({
//       key: "rtl",
//       stylisPlugins: [rtlPlugin],
//     });

//     setRtlCache(cacheRtl);
//   }, []);

//   const handleOnMouseEnter = () => {
//     if (miniSidenav && !onMouseEnter) {
//       setMiniSidenav(dispatch, false);
//       setOnMouseEnter(true);
//     }
//   };

//   const handleOnMouseLeave = () => {
//     if (onMouseEnter) {
//       setMiniSidenav(dispatch, true);
//       setOnMouseEnter(false);
//     }
//   };

//   const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

//   useEffect(() => {
//     document.body.setAttribute("dir", direction);
//   }, [direction]);

//   useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//   }, [pathname]);

//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }

//       if (route.route) {
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }

//       return null;
//     });

//   // const configsButton = (
//   //   <MDBox
//   //     display="flex"
//   //     justifyContent="center"
//   //     alignItems="center"
//   //     width="3.25rem"
//   //     height="3.25rem"
//   //     bgColor="white"
//   //     shadow="sm"
//   //     borderRadius="50%"
//   //     position="fixed"
//   //     right="2rem"
//   //     bottom="2rem"
//   //     zIndex={99}
//   //     color="dark"
//   //     sx={{ cursor: "pointer" }}
//   //     onClick={handleConfiguratorOpen}
//   //   >
//   //     <Icon fontSize="small" color="inherit">
//   //       settings
//   //     </Icon>
//   //   </MDBox>
//   // );

 

//   return (
//     <UserProvider>
//       {direction === "rtl" ? (
//         <CacheProvider value={rtlCache}>
//           <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
//             <CssBaseline />
//             {layout === "dashboard" && (
//               <>
//                 <Sidenav
//                   color={sidenavColor}
//                   brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
//                   brandName="Material Dashboard 2"
//                   routes={routes}
//                   onMouseEnter={handleOnMouseEnter}
//                   onMouseLeave={handleOnMouseLeave}
//                 />
//                 <Configurator />
//                 {/* {configsButton} */}
//               </>
//             )}
//             {layout === "vr" && <Configurator />}
//             <Routes>
//               {getRoutes(routes)}
//               {user ? (
//                 <>
//                   <Route path="/" element={<Navigate to="/dashboard" />} />
//                   <Route path="*" element={<Navigate to="/dashboard" />} />
//                 </>
//               ) : (
//                 <>
//                   <Route path="/" element={<Navigate to="/Login" replace />} />
//                   <Route path="/Login" element={<Basic />} />
//                 </>
//               )}
//             </Routes>
//           </ThemeProvider>
//         </CacheProvider>
//       ) : (
//         <ThemeProvider theme={darkMode ? themeDark : theme}>
//           <CssBaseline />
//           {layout === "dashboard" && (
//             <>
//               <Sidenav
//                 color={sidenavColor}
//                 brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
//                 brandName="DIGI"
//                 //  routes={{username} ? routes : []}
//                 routes={routes}
//                 onMouseEnter={handleOnMouseEnter}
//                 onMouseLeave={handleOnMouseLeave}
//               />
//               <Configurator />
//               {/* {configsButton} */}
//             </>
//           )}
//           {layout === "vr" && <Configurator />}
//           <Routes>
//             {getRoutes(routes)}
//             {user ? (
//               <>
//                 <Route path="/" element={<Navigate to="/dashboard" />} />
//                 <Route path="*" element={<Navigate to="/dashboard" />} />
//               </>
//             ) : (
//               <>
//                 <Route path="/" element={<Navigate to="/home" replace />} />
//                 <Route path="/home" element={<Basic />} />
//               </>
//             )}
//           </Routes>
//           <Footer/>
//         </ThemeProvider>
//       )}
//     </UserProvider>
      
//   );
// }
// App.js

// import React, { useState, useEffect, useMemo } from "react";
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";
// import MDBox from "components/MDBox";
// import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";
// import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";
// import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";
// import routes from "routes";
// import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
// import brandWhite from "assets/images/mainlogo.png";
// import brandDark from "assets/images/logo-ct-dark.png";
// import Basic from "layouts/authentication/sign-in";
// import { useUser } from "context/UserContext";
// import { UserProvider } from "context/UserContext";
// import Footer from "layouts/authentication/components/Footer";


// export default function App() {
//   const [controller, dispatch] = useMaterialUIController();
//   const {
//     miniSidenav,
//     direction,
//     layout,
//     openConfigurator,
//     sidenavColor,
//     transparentSidenav,
//     whiteSidenav,
//     darkMode,
//   } = controller;
//   const [onMouseEnter, setOnMouseEnter] = useState(false);
//   const [rtlCache, setRtlCache] = useState(null);
//   const { pathname } = useLocation();
//   const user = useUser();

//   useMemo(() => {
//     const cacheRtl = createCache({
//       key: "rtl",
//       stylisPlugins: [rtlPlugin],
//     });

//     setRtlCache(cacheRtl);
//   }, []);

//   const handleOnMouseEnter = () => {
//     if (miniSidenav && !onMouseEnter) {
//       setMiniSidenav(dispatch, false);
//       setOnMouseEnter(true);
//     }
//   };

//   const handleOnMouseLeave = () => {
//     if (onMouseEnter) {
//       setMiniSidenav(dispatch, true);
//       setOnMouseEnter(false);
//     }
//   };
//   // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

//   // const configsButton = (
//   //   <MDBox
//   //     display="flex"
//   //     justifyContent="center"
//   //     alignItems="center"
//   //     width="3.25rem"
//   //     height="3.25rem"
//   //     bgColor="white"
//   //     shadow="sm"
//   //     borderRadius="50%"
//   //     position="fixed"
//   //     right="2rem"
//   //     bottom="2rem"
//   //     zIndex={99}
//   //     color="dark"
//   //     sx={{ cursor: "pointer" }}
//   //     onClick={handleConfiguratorOpen}
//   //   >
//   //     <Icon fontSize="small" color="inherit">
//   //       settings
//   //     </Icon>
//   //   </MDBox>
//   // );
 

//   useEffect(() => {
//     document.body.setAttribute("dir", direction);
//   }, [direction]);

//   useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//   }, [pathname]);

//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }

//       if (route.route) {
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }

//       return null;
//     });

//   return (
//     <UserProvider>
//       {direction === "rtl" ? (
//         <CacheProvider value={rtlCache}>
//           <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
//             <CssBaseline />
//             {layout === "dashboard" && (
//               <>
//                 <Sidenav
//                   color={sidenavColor}
//                   brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
//                   brandName="Material Dashboard 2"
//                   routes={routes}
//                   onMouseEnter={handleOnMouseEnter}
//                   onMouseLeave={handleOnMouseLeave}
//                 />
//                 <Configurator />
//                   {/* {configsButton} */}
//               </>
//             )}
//             {layout === "vr" && <Configurator />}
//             <Routes>
//               {getRoutes(routes)}
//               {user ? (
//                 <>
//                   <Route path="/" element={<Navigate to="/dashboard" />} />
//                   <Route path="*" element={<Navigate to="/dashboard" />} />
//                 </>
//               ) : (
//                 <>
//                   <Route path="/" element={<Navigate to="/Login" replace />} />
//                   <Route path="/Login" element={<Basic />} />
//                 </>
//               )}
//             </Routes>
//           </ThemeProvider>
//         </CacheProvider>
//       ) : (
//         <ThemeProvider theme={darkMode ? themeDark : theme}>
//           <CssBaseline />
//           {layout === "dashboard" && (
//             <>
//               <Sidenav
//                 color={sidenavColor}
//                 brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite }
//                 brandName="3rdeye"
//                 routes={routes}
//                 onMouseEnter={handleOnMouseEnter}
//                 onMouseLeave={handleOnMouseLeave}
              
//               />
//               <Configurator />
//               {/* {configsButton} */}
//             </>
//           )}
//           {layout === "vr" && <Configurator />}
//           <Routes>
//             {getRoutes(routes)}
//             {user ? (
//               <>
//                 <Route path="/" element={<Navigate to="/dashboard" />} />
//                 <Route path="*" element={<Navigate to="/dashboard" />} />
//               </>
//             ) : (
//               <>
//                 <Route path="/" element={<Navigate to="/home" replace />} />
//                 <Route path="/home" element={<Basic />} />
//               </>
//             )}
//           </Routes>
//           {/* <Footer /> */}
//         </ThemeProvider>
//       )}
//     </UserProvider>
//   );
// }

import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";



import brandWhite from "assets/images/seedzzz.png";
import brandDark from "assets/images/seedzzz.png";
import Basic from "layouts/authentication/sign-in";
import { useUser } from "context/UserContext";
import { UserProvider } from "context/UserContext";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const user = useUser();

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  // Hide Sidenav on "/DeleteAccount", "/Login", and "/home"
  const shouldShowSidenav = !["/DeleteAccount", "/login", "/Login", "/deleteAccount"].includes(pathname);

  return (
    <UserProvider>
      {direction === "rtl" ? (
        <CacheProvider value={rtlCache}>
          <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
            <CssBaseline />
            {layout === "dashboard" && shouldShowSidenav && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Material Dashboard 2"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routes)}
              {user ? (
                <>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="*" element={<Navigate to="/dashboard" />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<Navigate to="/home" replace />} />
                  <Route path="/home" element={<Basic />} />
                </>
              )}
            </Routes>
          </ThemeProvider>
        </CacheProvider>
      ) : (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && shouldShowSidenav && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            {user ? (
              <>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Basic />} />
              </>
            )}
          </Routes>
          {/* <Footer /> */}
        </ThemeProvider>
      )}
    </UserProvider>
  );
}