// import React from "react";
// import { Box, Container, Grid, Button, Typography } from "@mui/material";
// // import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// // import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// // import Footer from "examples/Footer";
// import MDBox from "components/MDBox";
// import CoverLayout from "../components/CoverLayout";
// import DB from '../../../assets/images/sss.jpg';
// import DB1 from '../../../assets/images/mainlogo.png';
// import './sign-in.css'


// const Basic = () => {
//   const handleLoginClick = () => {
//     window.location.href =
//       "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://3rdeye.dhruthzucitech.solutions/home";
//   //"https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/home";
//      };

//   return (
//     <>
//       <div className="background" style={{ backgroundImage: `url(${DB})`, width: "100%", height: "100vh", backgroundSize: "cover", backgroundPosition: "center", position: "fixed", top: 0, left: 0, zIndex: -1 }}></div>
//       <Box sx={{ bgcolor: "", py: 2, mt: 6.8, px: 2 }}>
//         <img src={DB1} style={{
//           width: "90px",
//            height: "90px",
//           marginLeft: "76px",
//           marginTop: "-68px"
//         }} />
//       </Box>
//       <Container sx={{ mt: -15 }}>
//   <div className="signin-dashboard-container druthzuci">
//     <Typography variant="h1" align="center" style={{ fontFamily: "impact", fontSize: "3rem", backgroundImage: "linear-gradient( #0da3d7, #c1bda8)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", opacity: 1, transform: "none" }}>
//       DHRUTHZUCI TECH SOLUTIONS
//     </Typography>
//     <Typography variant="h1" color="#0da3d7" align="center" style={{ fontFamily: "impact", fontSize: "3rem", backgroundImage: "linear-gradient( #0da3d7, #c1bda8)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", opacity: 1, transform: "none" }}>
//       PRIVATE LIMITED
//     </Typography>
//   </div>
//   <div style={{ marginTop: "200px" }} className="signin-dashboard-container dz">
//     <Typography variant="h1" color="#487800" align="center" style={{ fontSize: '5rem',fontFamily:"sans-serif"}}>
//       3rdeye
//     </Typography>
//   </div>
// </Container>

//       <CoverLayout>
//         <MDBox
//           sx={{
//             minHeight: "20vh",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Container>
//             <Grid
//               container
//               spacing={3}
//               justifyContent="center"
//               alignItems="center"
//               sx={{ boxShadow: 3, p: 3, borderRadius: 2, bgcolor: "background.paper", mb: 9 }}
//             >
//               <Grid item xs={12} md={6} lg={9} textAlign="center">
//                 <Typography variant="body1" gutterBottom>
//                   Login To Explore More
//                 </Typography>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   size="large"
//                   onClick={handleLoginClick}
//                   startIcon={<i className="bi bi-person-circle" />}
//                 >
//                   <Typography variant="h6" color="#ffffed" align="center">
//                     LOGIN
//                   </Typography>
//                 </Button>
//               </Grid>
//             </Grid>
//           </Container>
//         </MDBox>
//       </CoverLayout>
//     </>
//   );
// };

// export default Basic;

// import React, { useEffect } from "react";
// import { Box, Container, Grid, Button, Typography } from "@mui/material";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import MDBox from "components/MDBox";
// import CoverLayout from "../components/CoverLayout";
// import DB from '../../../assets/images/sss.jpg';
// import DB1 from '../../../assets/images/mainlogo.png';
// import './sign-in.css'

// const Basic = () => {
//   useEffect(() => {
//     // Disable scrolling
//     document.body.style.overflow = 'hidden';
    
//     // Clean up the style on component unmount
//     return () => {
//       document.body.style.overflow = 'auto';
//     };
//   }, []);

//   const handleLoginClick = () => {
//     window.location.href =
//       //  "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://digiv2.dhruthzucitech.solutions/home";
//       "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/dashboard";
//    };

//   return (
//     <>
//       <div className="background" style={{ backgroundImage: `url(${DB})`, width: "100%", height: "100vh", backgroundSize: "cover", backgroundPosition: "center", position: "fixed", top: 0, left: 0, zIndex: -1 }}></div>
//       <Box sx={{ bgcolor: "rgba(230, 230, 250, 0)", py: 2, mt: 10, px: 2 }}>
//         <img src={DB1} style={{
//           width: "90px",
//            height: "90px",
//           marginLeft: "76px",
//           marginTop: "-68px"
//         }} />
//         <Container >
//           <Typography variant="h1" color="#ffffed" align="center">
//             DHRUTHZUCI TECH SOLUTIONS
//           </Typography>
//           <Typography variant="h1" color="#ffffed" align="center">
//             PRIVATE LIMITED
//           </Typography>
//           <div style={{ marginTop: "200px" }} className="signin-dashboard-container">
//             <Typography variant="h1" color="#ffffed" align="center" >
//               DIGI
//             </Typography>
//           </div>
//         </Container>
//       </Box>
//       <CoverLayout>
//         <MDBox
//           sx={{
//             minHeight: "20vh",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Container>
//             <Grid
//               container
//               spacing={3}
//               justifyContent="center"
//               alignItems="center"
//               sx={{ boxShadow: 3, p: 3, borderRadius: 2, bgcolor: "background.paper", mb: 9 }}
//             >
//               <Grid item xs={12} md={6} lg={9} textAlign="center">
//                 <Typography variant="body1" gutterBottom>
//                   Login To Explore More
//                 </Typography>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   size="large"
//                   onClick={handleLoginClick}
//                   startIcon={<i className="bi bi-person-circle" />}
//                 >
//                   <Typography variant="h6" color="#ffffed" align="center">
//                     LOGIN
//                   </Typography>
//                 </Button>
//               </Grid>
//             </Grid>
//           </Container>
//         </MDBox>
//       </CoverLayout>
//     </>
//   );
// };

// export default Basic;


import React from "react";
import { Box, Container, Grid, Button, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DB from '../../../assets/images/sss.jpg';
import DB1 from '../../../assets/images/mainlogo.png';
import './sign-in.css';
import Config from '../../../Config/config';
import MDButton from "components/MDButton";

const Basic = () => {
  const handleLoginClick = () => {
    const { Pingrice_API, Pingwheat_API, Pingtomato_API } = Config;

    console.log('API URLs:', { Pingrice_API, Pingwheat_API, Pingtomato_API });

    // Trigger API calls without waiting for them to complete
    fetch(Pingrice_API, { method: 'POST', headers: { 'Content-Type': 'application/json' } })
      .then(response => response.json())
      .then(data => console.log('Rice API Response:', data))
      .catch(error => console.error('Error calling Rice API:', error));

    fetch(Pingwheat_API, { method: 'POST', headers: { 'Content-Type': 'application/json' } })
      .then(response => response.json())
      .then(data => console.log('Wheat API Response:', data))
      .catch(error => console.error('Error calling Wheat API:', error));

    fetch(Pingtomato_API, { method: 'POST', headers: { 'Content-Type': 'application/json' } })
      .then(response => response.json())
      .then(data => console.log('Tomato API Response:', data))
      .catch(error => console.error('Error calling Tomato API:', error));

    console.log('Redirecting to Google login page...');

    // Redirect to the login page immediately
    window.location.href =
      // "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://3rdeye.dhruthzucitech.solutions/home";
      "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/home";
  };

  return (
    <>
      <div
        className="background"
        style={{
          backgroundImage: `url(${DB})`,
          width: "100%",
          height: "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1
        }}
      ></div>

      {/* Container for logo and title */}
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: { xs: 5, sm: 4, md: 6 }, // Responsive margin to prevent overlap
          }}
        >
          <img
            src={DB1}
            alt="Logo"
            style={{
              width: "100%",
              maxWidth: "90px",
              height: "auto",
              objectFit: "contain",
              marginBottom: '16px', // Add spacing between logo and text
            }}
          />
          <div className="signin-dashboard-container druthzuci">
            <Typography
              variant="h1"
              align="center"
              sx={{
                fontFamily: "impact",
                fontSize: { xs: "2rem", sm: "3rem" },
                backgroundImage: "linear-gradient(#0da3d7, #c1bda8)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                opacity: 1,
                transform: "none",
              }}
            >
              DHRUTHZUCI TECH SOLUTIONS
            </Typography>
            <Typography
              variant="h1"
              color="#0da3d7"
              align="center"
              sx={{
                fontFamily: "impact",
                fontSize: { xs: "2rem", sm: "3rem" },
                backgroundImage: "linear-gradient(#0da3d7, #c1bda8)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                opacity: 1,
                transform: "none",
              }}
            >
              PRIVATE LIMITED
            </Typography>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "80px" }} className="signin-dashboard-container dz">
            <Typography
              variant="h1"
              color="#487800"
              align="center"
              sx={{ fontSize: { xs: "3rem", sm: '5rem' }, fontFamily: "sans-serif" }}
            >
              3rdeye
            </Typography>
          </div>
        </Box>
      </Container>

      <MDBox
        sx={{
          minHeight: "20vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Grid
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              boxShadow: 3,
              p: 3,
              borderRadius: 2,
              bgcolor: "background.paper",
              mb: 9,
            }}
          >
            {/* Reduced width of the Grid item */}
            <Grid item xs={12} sm={6} md={4} lg={3} textAlign="center">
              <Typography variant="body1" gutterBottom>
                Login To Explore More
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleLoginClick}
                startIcon={<i className="bi bi-person-circle" />}
              >
                <Typography variant="h6" color="#ffffed" align="center">
                  LOGIN
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </MDBox>
    </>
  );
};

export default Basic;