import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';


const UserContext = createContext();
 
export function useUser() {
  return useContext(UserContext);
}

export function useUserDispatch() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context.dispatch;
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    const accessToken = localStorage.getItem('accessToken');
    

    if (storedUser) {
      return JSON.parse(storedUser);

      console.log('stored user:', storedUser);
    }
    return null;
  });

  const [accessToken, setAccessToken] = useState(''); 

  const dispatch = (action) => {
    switch (action.type) {
      case 'SET_USER':
        setUser(action.payload);
        localStorage.setItem('user', JSON.stringify(action.payload));
        break;
      case 'SET_ACCESS_TOKEN':
          setAccessToken(action.payload);
          localStorage.setItem('accessToken', action.payload); 
          break; 
      case 'LOGOUT':
        setUser(null);
        localStorage.removeItem('user');
        setAccessToken('');
        break;
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  useEffect(() => {
    const extractTokensFromUrl = () => {
      const url = window.location.href;
     
      const hashIndex = url.indexOf('#');
      if (hashIndex !== -1) {
        const fragment = url.substring(hashIndex + 1);
        const tokenPairs = fragment.split('&');
        const tokens = {};
        tokenPairs.forEach((pair) => {
          const [key, value] = pair.split('=');
          tokens[key] = decodeURIComponent(value);
        });
        return tokens;
      }
      return {};
    };
  
    try {
      const tokens = extractTokensFromUrl();
  
      const idToken = tokens.id_token;
      const accessToken = tokens.access_token;
  
      if (idToken) {
        const decodedIdToken = jwtDecode(idToken);
        dispatch({ type: 'SET_USER', payload: decodedIdToken });
      }
  
      if (accessToken) {
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
        setAccessToken(accessToken);  // Add this line to set accessToken in the state
      }
      window.location.hash = '';
    } catch (error) {
    }
  }, [dispatch]);
 


  return (
    <UserContext.Provider value={{ user, dispatch, accessToken }}>
      {children}
    </UserContext.Provider>
  );
}