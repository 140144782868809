import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TableContainer,

} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const SeedClassification = () => {
  const [category, setCategory] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [reportData, setReportData] = useState([]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleUploadImage = async () => {
    if (!category) {
      alert("Please select a category.");
      return;
    }

    if (!imageFile) {
      alert("Please select an image to upload.");
      return;
    }

    const imageApiUrl = `https://mexqi5uelf.execute-api.ap-south-1.amazonaws.com/dev/digi-s3-bucket%2f${encodeURIComponent(
      category
    )}/${encodeURIComponent(imageFile.name)}`;
    const apiKey = "vRtW4LOFB61VjXvcvRtKu6QOItTs7b7w4d2QoI2n";

    try {
      const imageUploadResponse = await fetch(imageApiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": imageFile.type,
          "x-api-key": apiKey,
        },
        body: imageFile,
      });

      if (!imageUploadResponse.ok) {
        alert("Failed to upload the image.");
        return;
      }

      alert("Image uploaded successfully!");
      setCategory("");
      setImageFile(null);

      // Trigger the report retrieval after a successful upload
      setLoader(true); // Start the loader
      setTimeout(() => {
        handleGetReport();
      }, 20000); // 20-second delay before fetching the report
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while uploading the image.");
    }
  };

  const handleGetReport = async () => {
    const grainType = "rice";
    const username = "Unknown";
    const report = false;
    const apiKey = "vRtW4LOFB61VjXvcvRtKu6QOItTs7b7w4d2QoI2n";
    const retrieveApiUrl =
      "https://hppjgnsgx4.execute-api.ap-south-1.amazonaws.com/dev/digi-get-lambda";
    const fetchUrl = `${retrieveApiUrl}?grainType=${grainType}&userid=${username}&report=${report}`;

    try {
      const response = await fetch(fetchUrl, {
        method: "GET",
        headers: {
          "x-api-key": apiKey,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the report.");
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (data && data["body-json"]) {
        setReportData(data["body-json"]);
      } else {
        alert("No data found for the specified criteria.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while fetching the report.");
    } finally {
      setLoader(false); // Stop the loader after fetching the report
    }
  };

  const rows = reportData.map((report) => ({
    date: (
      <MDTypography variant="button" fontWeight="medium">
        {report.date || "N/A"}
      </MDTypography>
    ),
    category: (
      <MDTypography variant="button" fontWeight="medium">
        {report.category || "N/A"}
      </MDTypography>
    ),
    class: (
      <MDTypography variant="button" fontWeight="medium">
        {report.class || "N/A"}
      </MDTypography>
    ),
    file_name: (
      <MDTypography variant="button" fontWeight="medium">
        {report.file_name || "N/A"}
      </MDTypography>
    ),

    image: report.cdn_url ? (
        <a href={report.cdn_url} target="_blank" rel="noopener noreferrer">
          <img
            src={report.cdn_url}
            alt={`Seed image for ${report.category || "Unknown"}`}
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
            onError={(e) => (e.target.style.display = "none")} // Hide image if it fails to load
          />
        </a>
      ) : (
        "No Image"
      ),
    score: (
      <MDTypography variant="button" fontWeight="medium">
        {report.score || "N/A"}
      </MDTypography>
    ),
  }));

  const columns = [
    { Header: "date", accessor: "date", align: "left" },
    { Header: "Seed Category", accessor: "category", align: "left" },
    { Header: "Seed Class", accessor: "class", align: "center" },
    { Header: "File Name", accessor: "file_name", align: "center" },
    { Header: "Image", accessor: "image", align: "center" },
    { Header: "Score", accessor: "score", align: "center" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Container maxWidth="sm">
        <Box
          sx={{
            bgcolor: "background.paper",
            padding: 3,
            borderRadius: 2,
            boxShadow: 1,
            marginTop: 3
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Seed Classification
          </Typography>

          <FormControl fullWidth margin="normal">
            <InputLabel id="category-label">Select Category</InputLabel>
            <Select
              labelId="category-label"
              value={category}
              onChange={handleCategoryChange}
              sx={{ width: "60%", height: "40px" }}
              required
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              <MenuItem value="rice">Rice</MenuItem>
            </Select>
          </FormControl>

          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            required
            style={{ width: "100%", padding: "10px", marginTop: "5px" }}
          />

          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <MDButton
              variant="contained"
              color="primary"
              onClick={handleUploadImage}
            >
              Upload Image
            </MDButton>
          </Box>

          {loader && (
            <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
          )}

          {!loader && reportData.length > 0 && (
            <TableContainer sx={{ marginTop: 2 }}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={false}
                pagination={true}
                noEndBorder
              />
            </TableContainer>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default SeedClassification;