import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
} from "@mui/material";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PieChart from "examples/Charts/PieChart"; // Import PieChart component

const SeedDataFetcher = () => {
  const [seedName, setSeedName] = useState("");
  const [seedData, setSeedData] = useState([]);
  const [error, setError] = useState("");
  const [chartData, setChartData] = useState(null);

  const handleSeedChange = (event) => {
    setSeedName(event.target.value);
  
    // Reset state variables when seed name changes
    setSeedData([]);
    setChartData(null);
    setError("");
  };
  

  const fetchSeedData = () => {
    if (!seedName) {
      alert("Please select a Seed Name.");
      console.log("Error: No seed name selected.");
      return;
    }

    const url = new URL("https://tb52zeruwh.execute-api.ap-south-1.amazonaws.com/dev/dataGet");
    url.searchParams.append("seed_name", seedName);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const fetchedData = data["body-json"].body;
        console.log("fetchedData", fetchedData);
        setSeedData(fetchedData || []);
        setError(fetchedData && fetchedData.length > 0 ? "" : "No data available");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      });
  };

  const generateReport = () => {
    if (!seedName || seedData.length === 0) {
      alert("No data available for generating the report.");
      return;
    }
  
    // Calculate unique species counts
    const speciesCounts = seedData.reduce((acc, seed) => {
      const species = seed.species_name || "Unknown";
      acc[species] = (acc[species] || 0) + 1;
      return acc;
    }, {});
  
    // Format chartData in the required format
    const labels = Object.keys(speciesCounts); // Unique species
    const data = Object.values(speciesCounts); // Count of each species
  
    setChartData({
      labels,
      datasets: {
        label: "Count", // Dataset label
        data, // Count of data entries for each species
        backgroundColors: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    });
  };
  

  // Map the API data into the table rows format
  const rows = seedData.map((seed) => ({
    seed_no: <MDTypography variant="button" fontWeight="medium">{seed.seed_no}</MDTypography>,
    acquisition_date: seed.acquisition_date || "N/A",
    species_name: seed.species_name || "N/A",
    origin_country: seed.origin_country || "N/A",
    origin_state: seed.origin_state || "N/A",
    climate_grown: seed.climate_grown || "N/A",
    fertilizers_used: seed.fertilizers_used || "N/A",
    soil_type: seed.soil_type || "N/A",
    image: seed.image ? (
      <a href={seed.image} target="_blank" rel="noopener noreferrer">
        <img src={seed.image} alt={`Image of ${seed.species_name}`} style={{ width: "50px", height: "50px" }} />
      </a>
    ) : (
      "No Image"
    ),
  }));

  // Define the columns array
  const columns = [
    { Header: "Seed No", accessor: "seed_no", align: "left" },
    { Header: "Acquisition Date", accessor: "acquisition_date", align: "left" },
    { Header: "Species Name", accessor: "species_name", align: "left" },
    { Header: "Origin Country", accessor: "origin_country", align: "left" },
    { Header: "Origin State", accessor: "origin_state", align: "left" },
    { Header: "Climate Grown", accessor: "climate_grown", align: "left" },
    { Header: "Fertilizers Used", accessor: "fertilizers_used", align: "left" },
    { Header: "Soil Type", accessor: "soil_type", align: "left" },
    { Header: "Image", accessor: "image", align: "center" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Seed Data
        </Typography>

        <FormControl fullWidth margin="normal" size="medium">
          <InputLabel>Select Seed Name</InputLabel>
          <Select sx={{ width: "50%", height: "40px" }} value={seedName} onChange={handleSeedChange}>
            <MenuItem value="">Select Seed Name</MenuItem>
            <MenuItem value="Rice">Rice</MenuItem>
            <MenuItem value="Tomato">Tomato</MenuItem>
            <MenuItem value="Chickpea">Chickpea</MenuItem>
            <MenuItem value="Pigeonpea">Pigeonpea</MenuItem>
            <MenuItem value="Sorghum">Sorghum</MenuItem>
            <MenuItem value="Groundnut">Groundnut</MenuItem>
            <MenuItem value="Pearl Millet">Pearl Millet</MenuItem>
            <MenuItem value="Finger Millet">Finger Millet</MenuItem>
            <MenuItem value="Foxtail Millet">Foxtail Millet</MenuItem>
            <MenuItem value="Little Millet">Little Millet</MenuItem>
            <MenuItem value="Kodo Millet">Kodo Millet</MenuItem>
            <MenuItem value="Proso Millet">Proso Millet</MenuItem>
            <MenuItem value="Barnyard Millet">Barnyard Millet</MenuItem>
          </Select>
        </FormControl>

        <MDButton variant="contained" color="primary" onClick={fetchSeedData} sx={{ mt: 2, mb: 3 }}>
          Fetch Seed Data
        </MDButton>
        <MDButton variant="contained" color="secondary" onClick={generateReport} sx={{ mt: 2, mb: 3, ml: 2 }}>
          Get Report
        </MDButton>

        {error && <Typography color="error">{error}</Typography>}
        {chartData && (
          <PieChart
          icon
            title={`Species Distribution for ${seedName}`}
            description="Pie chart representing species distribution"
            chart={chartData}
            height="20rem"
          />
        )}

        {seedData.length > 0 && (
          <TableContainer component={Paper}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage
              showTotalEntries={false}
              pagination
              noEndBorder
            />
          </TableContainer>
        )}


      </Container>
    </DashboardLayout>
  );
};

export default SeedDataFetcher;


// import React from "react";
// import PieChart from "examples/Charts/PieChart";

// function SeedDataFetcher() {
//   const chartData = {
//     labels: ["Category A", "Category B", "Category C"],
//     datasets: {
//       label: "Sales Distribution",
//       data: [40, 35, 25],
//       backgroundColors: ["#FF6384", "#36A2EB", "#FFCE56"],
//     },
//   };
  

//   return (
//     <div>
//       <PieChart
//         icon={{ color: "info", component: "pie_chart" }}
//         title="Sales Distribution"
//         description="Distribution of sales across categories"
//         height="20rem"
//         chart={chartData}
//       />
//     </div>
//   );
// }

// export default SeedDataFetcher;
