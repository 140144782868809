import Icon from "@mui/material/Icon";
import { faGears, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "layouts/dashboard";
import LandingPage from "layouts/landing";
import UploadScreen from "layouts/UploadScreen/UploadScreen";
import DataTable from "layouts/tables";
import Basic from "layouts/authentication/sign-in";
import Reports from "layouts/tables";
import Aboutus from "layouts/AboutUs";
import DeleteAccountPage from "layouts/DeleteUser";
import SeedAcquisitionForm from "layouts/aquisition/aquisition";
import SeedAcquisitionFormChilli from "layouts/aquisition/aquisitionChilli";
import SeedCharacterization from "layouts/characterization/characterization";
import SeedClassification from "layouts/classification/classification";
import SeedDataFetcher from "layouts/SeedData/GetSeedData";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <LandingPage />,
    protected: false,
  },
  // {
  //   type: "collapse",
  //   name: "Field Management",
  //   key: "SeedManagement",
  //   icon: <Icon fontSize="small">home</Icon>,
  //   route: "/home",
  //   component: <LandingPage />,
  //   protected: false,
  // },
  // {
  //   type: "collapse",
  //   name: "System Administration",
  //   key: "SystemAdministration",
  //   icon: <Icon fontSize="small">home</Icon>,
  //   route: "/home",
  //   component: <LandingPage />,
  //   protected: false,
  // },
  {
    type: "collapse",
    name: "Aquisition Data",
    key: "AquisitionData",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/AquisitionData",
    component: <SeedDataFetcher />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/aquisition",
    component: <SeedAcquisitionForm />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/aquisition-chilli",
    component: <SeedAcquisitionFormChilli />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/characterization",
    component: <SeedCharacterization />,
    protected: false,
  },
  {
    // type: "collapse",
    // name: "System Administration",
    // key: "SystemAdministration",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/classification",
    component: <SeedClassification />,
    protected: false,
  },

];

export default routes;
