const Config = {
    api_key: 'vRtW4LOFB61VjXvcvRtKu6QOItTs7b7w4d2QoI2n',
    Upload_API: 'https://mexqi5uelf.execute-api.ap-south-1.amazonaws.com/dev/digi-s3-bucket%2f',
    Retrieve_API: 'https://hppjgnsgx4.execute-api.ap-south-1.amazonaws.com/dev/digi-get-lambda',
    Submittrain_API: 'https://tyxteistfi.execute-api.ap-south-1.amazonaws.com/dev/submit-train',
    Pingrice_API:'https://tyxteistfi.execute-api.ap-south-1.amazonaws.com/dev/test-inference',
    Pingwheat_API:'https://tyxteistfi.execute-api.ap-south-1.amazonaws.com/dev/test-inference-wheat',
    Pingtomato_API:'https://tyxteistfi.execute-api.ap-south-1.amazonaws.com/dev/test-inference-tomato',
};

export default Config;